import { useEffect, useState } from "react";

import {
    ActiveSubscriptions,
    FireCMSBackend,
    FireCMSProject,
    PlanChip,
    Subscription,
    useFireCMSBackend,
    useSubscriptionsForUserController
} from "@firecms/cloud";
import { CircularProgressCenter, useBrowserTitleAndIcon } from "@firecms/core";
import {
    Alert,
    Button,
    CancelIcon,
    CenteredView,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from "@firecms/ui";
import { ProLicenses } from "../components/licenses/ProLicenses";

type ProjectWithSubscription = FireCMSProject & Partial<Subscription>;

export function UserSubscriptions({
                                      fireCMSBackend,
                                  }: {
    fireCMSBackend: FireCMSBackend;
}) {

    const {
        projects
    } = fireCMSBackend;

    useBrowserTitleAndIcon("User Subscriptions")

    const {
        products,
        activeSubscriptions
    } = useSubscriptionsForUserController();

    if (activeSubscriptions === undefined || products === undefined) {
        return <CircularProgressCenter/>
    }

    const projectsWithSubscriptions: ProjectWithSubscription[] = (projects ?? [])
        .map(project => {
            const subscription = activeSubscriptions.find(s => s.metadata.projectId === project.projectId) ?? {};
            return {
                ...project,
                ...subscription
            }
        })
        .toSorted((a, b) => {
            return a.subscription_plan.localeCompare(b.subscription_plan);
        });

    return (
        <CenteredView maxWidth={"4xl"} className={"py-8 w-full"}>


            <ProLicenses/>

            {projectsWithSubscriptions.length > 0 && <>
                <Typography variant={"h4"} className={"mt-16 mb-8"}>Your FireCMS Cloud projects</Typography>
                <Table className="table-auto">
                    <TableBody>
                        {projectsWithSubscriptions.map((project) => (
                            <TableRow key={project.projectId}>
                                <TableCell
                                    className={"w-full"}
                                    align="left">
                                    <Typography variant={"subtitle2"} className={"flex-grow"}>
                                        {project.name}
                                    </Typography>
                                </TableCell>

                                <TableCell
                                    className={"w-max flex flex-row gap-2"}
                                    align={"right"}>
                                    {project.status && <ProjectSubscriptionCell projectWithSubs={project}/>}
                                </TableCell>

                                <TableCell
                                    className={"w-max"}
                                    align="right">
                                    <PlanChip subscriptionPlan={project.subscription_plan}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </>}

            {/*<Typography variant={"h4"} className={"mt-16 mb-8"}>Subscriptions</Typography>*/}

            {/*<Typography*/}
            {/*    className="mb-2">*/}
            {/*    Here you will see all the subscriptions you have purchased.*/}
            {/*    Note that you might be related to projects that you are not*/}
            {/*    directly subscribed to.*/}
            {/*</Typography>*/}

            {/*{activeSubscriptions.length > 0 &&*/}
            {/*    <ActiveSubscriptions*/}
            {/*        activeSubscriptions={activeSubscriptions}/>}*/}

            {/*{activeSubscriptions.length === 0 &&*/}
            {/*    <Alert>*/}
            {/*        You have no active subscriptions.*/}
            {/*    </Alert>}*/}

        </CenteredView>
    );

}

function ProjectSubscriptionCell({ projectWithSubs }: { projectWithSubs: ProjectWithSubscription }) {
    const { projectsApi } = useFireCMSBackend();

    const statusText = getStatusText(projectWithSubs as Subscription);
    const [stripePortalUrl, setStripePortalUrl] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!stripePortalUrl && projectWithSubs.id) {
            projectsApi.getStripeCancelLinkForSubscription(projectWithSubs.id)
                .then(setStripePortalUrl);
        }
    }, []);

    return <>
        {projectWithSubs.cancel_at && <Tooltip
            title={`This subscription was <b>cancelled</b> and will be active until ${projectWithSubs.cancel_at.toDate().toLocaleDateString()}`}>
            <CancelIcon/>
        </Tooltip>}
        {projectWithSubs.status && <Button component={"a"}
                                           variant={"text"}
                                           className={" " + projectWithSubs.canceled_at ? undefined : "text-text-secondary dark:text-text-secondary-dark"}
                                           size={"small"}
                                           href={stripePortalUrl}
                                           target="_blank"
                                           rel="noreferrer">
            {projectWithSubs.canceled_at ? "Renew" : "Manage"}</Button>}
        <Chip
            size={"medium"}
            colorScheme={statusText === "Active" ? "greenDark" : "orangeDark"}>
            {statusText}
        </Chip>
    </>;
}

export function getStatusText(subscription: Subscription) {
    if (subscription.status === "active") return "Active";
    if (subscription.status === "trialing") return "Trialing";
    if (subscription.status === "past_due") return "Past due";
    if (subscription.status === "canceled") return "Canceled";
    if (subscription.status === "unpaid") return "Unpaid";
    if (subscription.status === "incomplete") return "Incomplete";
    if (subscription.status === "incomplete_expired") return "Incomplete expired";
    return "Unknown";
}
